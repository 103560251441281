









































































































































import { Mixins, Component } from 'vue-mixin-decorator';
import CircleNumber from '@/components/CircleNumber.vue';
// eslint-disable-next-line import/no-cycle
import LunchGameMixin from '@/mixins/luanchGameMixin';
import AuthConnector from '@/connector/Auth.vue';

@Component({
  components: {
    CircleNumber,
    AuthConnector,
  },
})
export default class Home extends Mixins<LunchGameMixin>(LunchGameMixin) {
  async playGame(id?: string) {
    const data = await this.luanchGame(id);
    if (data) {
      window.location.href = data;
    }
  }
}
